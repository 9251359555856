.p-header h2{
    text-align: center;
    padding: 15px;
    font-size: 40px;
    color: rgb(16, 40, 48);
  
}

.p1-all{
    display:flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.p-about{
    max-width: 600px;
    text-align: justify;
}

.p-image img{
    width: 400px;
    height: 450px;
   
}

.p-banner{
    background-image: url('https://www.questinc.com/images/home/slide-hero_it.jpeg');
    color: white;
    height: 200px;
    align-items: center;
    justify-content: center;
    display: flex;
    
}

.p-banner h1{
    text-align: center;
    font-family: sans-serif;
    font-size: 50px;
   
}